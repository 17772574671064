@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Bold.eot');
  src: url('./Gotham-Bold.eot?#iefix') format('embedded-opentype'), url('./Gotham-Bold.woff') format('woff'),
    url('./Gotham-Bold.ttf') format('truetype'), url('./Gotham-Bold.svg#Gotham-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-BoldItalic.eot');
  src: url('./Gotham-BoldItalic.eot?#iefix') format('embedded-opentype'), url('./Gotham-BoldItalic.woff') format('woff'),
    url('./Gotham-BoldItalic.ttf') format('truetype'), url('./Gotham-BoldItalic.svg#Gotham-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-MediumItalic.eot');
  src: url('./Gotham-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./Gotham-MediumItalic.woff') format('woff'), url('./Gotham-MediumItalic.ttf') format('truetype'),
    url('./Gotham-MediumItalic.svg#Gotham-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Medium.eot');
  src: url('./Gotham-Medium.eot?#iefix') format('embedded-opentype'), url('./Gotham-Medium.woff') format('woff'),
    url('./Gotham-Medium.ttf') format('truetype'), url('./Gotham-Medium.svg#Gotham-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Light.eot');
  src: url('./Gotham-Light.eot?#iefix') format('embedded-opentype'), url('./Gotham-Light.woff') format('woff'),
    url('./Gotham-Light.ttf') format('truetype'), url('./Gotham-Light.svg#Gotham-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-LightItalic.eot');
  src: url('./Gotham-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./Gotham-LightItalic.woff') format('woff'), url('./Gotham-LightItalic.ttf') format('truetype'),
    url('./Gotham-LightItalic.svg#Gotham-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-Book.eot');
  src: url('./Gotham-Book.eot?#iefix') format('embedded-opentype'), url('./Gotham-Book.woff') format('woff'),
    url('./Gotham-Book.ttf') format('truetype'), url('./Gotham-Book.svg#Gotham-Book') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham';
  src: url('./Gotham-BookItalic.eot');
  src: url('./Gotham-BookItalic.eot?#iefix') format('embedded-opentype'), url('./Gotham-BookItalic.woff') format('woff'),
    url('./Gotham-BookItalic.ttf') format('truetype'), url('./Gotham-BookItalic.svg#Gotham-BookItalic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: fallback;
}
